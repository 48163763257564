import initialStore from "../initialStore";
import { CHANGE_RESPONSE_ERROR, CLEAR_RESPONSE_ERROR } from "@actions/actionTypes";

export default function responseErrorsReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case CHANGE_RESPONSE_ERROR:
            return  {...state, ...action.error}
        case CLEAR_RESPONSE_ERROR:
            if (state[action.error]) {
                let newState = Object.assign({}, state);
                delete newState[action.error];
                return newState;
            }
            return state;
        default:
            return state;
    }
}
