import initialStore from "../initialStore";
import { SET_TIMER } from "@actions/actionTypes";

export default function timerReducer(state = initialStore.timer, action) {
    switch (action.type) {
        case SET_TIMER:
            return  action.timer
        default:
            return state;
    }
}
