import {
    REQUEST_GAME_CANCEL,
    REQUEST_GAME_RESULT,
    REQUEST_GAME_START,
    REQUEST_GAME_INFO, REQUEST_GAME_IS_ACTIVE
} from "./actionTypes";

export function  gameStart() {
    return dispatch => {
        dispatch({type: REQUEST_GAME_START})
    }
}
export function  gameResult() {
    return dispatch => {
        dispatch({type: REQUEST_GAME_RESULT})
    }
}

export function  gameCancel() {
    return dispatch => {
        dispatch({type: REQUEST_GAME_CANCEL})
    }
}

export function gameInfo() {
    return dispatch => {
        dispatch({type: REQUEST_GAME_INFO})
    }
}

export function gameIsActive(isActive) {
    return dispatch => {
        dispatch({type: REQUEST_GAME_IS_ACTIVE, isActive})
    }
}