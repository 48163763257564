import { CHANGE_LOADING, SET_ERROR_MSG, CLEAR_RESPONSE_ERROR , CHANGE_RESPONSE_ERROR} from "@actions/actionTypes";
import { isRequest, isSetData, isAddOrEditData } from "../../utils/CommonUtils";

export default function loadingMiddleware() {
    return ({dispatch}) => next => (action) => {
        if (typeof action === 'function')  return next(action);

        if (action.type === SET_ERROR_MSG && action.data.actionType) {
            dispatch({type: CHANGE_RESPONSE_ERROR, error:{[action.data.actionType]: true}});
            return next(action);
        }
        let requestType = isRequest(action.type);

        if (requestType) {
            if (action.data && action.data.id) {
                dispatch({type: CHANGE_LOADING, loading:{[requestType + action.data.id]: true}});
            } else {
                dispatch({type: CHANGE_LOADING, loading:{[requestType]: true}});
            }
            return next(action);
        }

        let setType = isSetData(action.type);

        if (setType) {
            dispatch({type: CLEAR_RESPONSE_ERROR, error: action.type.replace('SET', 'REQUEST') + '_ERROR'});
            if (action.data && action.data.id) {
                dispatch({type: CHANGE_LOADING, loading:{[setType + action.data.id]: false}});
                setTimeout(() => { dispatch({type: CHANGE_LOADING, loading:{[setType + action.data.id]: undefined}});}, 4);
            } else {
                let addEditType = isAddOrEditData(action.type);
                dispatch({type: CHANGE_LOADING, loading:{[setType]: false}});
                if (addEditType) {
                    setTimeout(() => { dispatch({type: CHANGE_LOADING, loading:{[setType]: undefined}});}, 4);
                }
            }
            return next(action);
        }

        return next(action);
    };
}