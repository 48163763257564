const serverTimer = `function serverTimer() {
    let that = this;
    this.time;
    this.endTicker;
    this.timer;
    this.ticker
    this.diffTimer = 0;

    self.onmessage = function (timeTicker) {
        const {type, data} = JSON.parse(timeTicker.data);
        if (type === "SET_SERVER_TIME") {
            that.setTime(data)
        }
        if (type === "SET_TIME_TICKER") {
            that.setGameTime(data)
        }
    };

    const incrementTime = () => {
        this.time += 1000;
        if (this.endTicker) this.calculateDiff(this.endTicker);
        if (this.diffTimer !== 1000) {
            this.diffTimer = 1000;
            clearInterval(this.timer);
            this.contTime();
        }

    }

    this.setTime = function (timestamp) {
        // if (this.time === undefined || isNaN(this.time)) {
            clearInterval(this.timer);
            var m = timestamp % 1000;
            this.diffTimer = 1000 - m;
            this.time = timestamp - m;
            this.contTime()
        // }

        if (this.endTicker) {
            this.calculateDiff(this.endTicker)
        }
    }


    this.contTime = function () {
        this.timer = setInterval(incrementTime, this.diffTimer)
    }
    this.contTime()

    this.calculateDiff = function (endTime) {
        let diff = endTime - this.time;
        if (diff <= 0) {
            this.endTicker = undefined
            diff = 0;
        }
        this.ticker = diff
        this.getDiff(diff)
    }

    this.getDiff = function (diff) {
        self.postMessage(JSON.stringify({
            type: "GAME_TIMER_DIFF",
            data: diff
        }))
    }

    this.setGameTime = function (gameTime) {
        if ( gameTime && gameTime.timerEnd) {
            this.endTicker = gameTime.timerEnd;
            if (this.time > 10000) {
                this.calculateDiff(gameTime.timerEnd)
            }
        } else {
            this.endTicker = undefined
            this.ticker = undefined
            this.getDiff(undefined)
        }

    }
}`;

export default serverTimer;