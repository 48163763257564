import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from "./redux/configureStore";
import App from './App';
import {Provider as ReduxProvider} from "react-redux";

const store = configureStore();

ReactDOM.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
  document.getElementById('root')
);
