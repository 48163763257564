import initialStore from "../initialStore";
import {RESET_ERROR_MSG, SET_ERROR_MSG} from "@actions/actionTypes";

export default function errorReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_ERROR_MSG:
            return  action.data;
        case RESET_ERROR_MSG:
            return {...state, messages: ""}
        default:
            return state;
    }
}
