import React, { useEffect, useState } from "react";
import {connect} from "react-redux";

export const ResponseContext = React.createContext();

const ResponseObserver = ({children, responseErrors, loading}) => {

    const [subscribedAction, setSubscribedAction] = useState([]);

    const subscribeForError = (actionType, cb, id = '') => {
        setSubscribedAction(prev => [...prev, {
            loadingType: actionType.replace('SET', '').replace('REQUEST', '').toLowerCase(),
            actionType,
            cb,
            id
        }]);
    }

    const unSubscribeForError = (actionType) => {
        setSubscribedAction(prev => prev.filter(item => item.actionType != actionType));
    }

    useEffect(() => {
        subscribedAction.forEach(item => {
            if (responseErrors[item.actionType + "_ERROR"] || loading[item.loadingType + item.id] || loading[item.loadingType + item.id] === undefined) {
                // error,
                // console.log("responseErrors", responseErrors)
            } else {
                item.cb();
            }
        });
    }, [loading])

    return (
        <ResponseContext.Provider value={{ subscribeForError, unSubscribeForError }}>
            {children}
        </ResponseContext.Provider>
    );
}


function mapStateToProps(state) {
    return {
        responseErrors: state.responseErrors,
        loading: state.loading
    };
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseObserver);