import { SET_GAME_IS_ACTIVE } from "@actions/actionTypes";

export default function gameIsActiveReducer(state = false, action) {
    switch (action.type) {
        case SET_GAME_IS_ACTIVE:
            return action.isActive;
        default:
            return state;
    }
}
