import React, {useEffect} from 'react'
import {IntlProvider} from "react-intl";
import {connect, Provider as ReduxProvider} from "react-redux";
import MainPage from './pages/contents/MainPage'
import translations from './translations'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {LanguageCode} from "./constants/Enums";
import "./assets/sass/main.sass";
import './App.sass';
import routes from "./routes";
import ResponseObserver from "./hooks/ResponseObserver";

function App({lang}) {
    return (
        <IntlProvider
            locale={LanguageCode[lang]}
            messages={translations[LanguageCode[lang].toLowerCase()] ? translations[LanguageCode[lang].toLowerCase()] : {}}
            defaultLocale="EN">
            <Router>
                <div>
                    <Switch>
                        <ResponseObserver>
                            <MainPage/>
                        </ResponseObserver>
                        <Route exact path="/" render={() => <Redirect to={routes[0].path}/>}/>
                    </Switch>
                </div>
            </Router>
        </IntlProvider>
    );
}

function mapStateToProps(state) {
    return {
        lang: state.settings.lang.id
    };
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(App);
