import initialStore from "../initialStore";
import { SET_START_PATH } from "@actions/actionTypes";

export default function pathReducer(state = '', action) {
    switch (action.type) {
        case SET_START_PATH:
            return action.path;
        default:
            return state;
    }
}
